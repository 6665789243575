import { Box, Grid, Typography } from '@mui/material';
import { navbarStyle, TypographyStyle } from '../Theme/styles';
import Heading from '../heading/Heading';
import packages from '../../packages.png';

const Packages = () => {
  return (
    <>
      <Heading heading={'Packages'} />
      <Grid container sx={{ height: 'auto', width: '100%' }} mt={4}>
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={6} mb={2}>
          <Box
            style={{
              backgroundImage: `url(${packages})`,
              width: '464px',
              height: '444px',
              objectFit: 'cover',
              display: 'flex',
              backgroundSize: 'cover',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></Box>
        </Grid>
        <Grid item xs={12} md={5} pt={8}>
          <Box mb={4}>
            <Typography
              sx={{
                fontSize: '20px',
                color: 'black',
                lineHeight: '1.5em',
                fontSize: '18px',
              }}
            >
              <li>11 Plus and KS3 Science Classes: £15 per lesson (1 hour)</li>
            </Typography>
            <Typography
              sx={{
                fontSize: '30px',
                color: 'black',
                lineHeight: '1.5em',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Discount: £25 for 2
              hours
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: '20px',
                color: 'black',
                lineHeight: '1.5em',
                fontSize: '18px',
              }}
            >
              <li>GCSE Physics Class: £25 (1 hour)</li>
            </Typography>
            <Typography
              sx={{
                fontSize: '30px',
                color: 'black',
                lineHeight: '1.5em',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Discount: £35 for 2
              hours
            </Typography>
          </Box>
          <Box width='100%' display='flex' justifyContent='center'>
            <Box sx={{ ...navbarStyle, width: '20%', marginTop: '30px' }}>
              <a
                href='/contact'
                style={{ ...TypographyStyle, textDecoration: 'none' }}
              >
                Get in Touch
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default Packages;
