import { Box, Typography } from '@mui/material';
import { TypographyStyle } from '../Theme/styles';
const Heading = ({ heading, subHeading }) => {
  return (
    <Box
      container
      sx={{
        height: '200px',
        backgroundColor: '#005CAB',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      xs={12}
      mt={1}
      mb={1}
    >
      <Box>
        <Box width='100%' display='flex' justifyContent={'center'}>
          <Typography
            sx={{
              ...TypographyStyle,
              fontSize: '28px', // Default font size for smaller screens
              fontWeight: 700,
              color: 'white',
              '@media (min-width: 768px)': {
                // Adjust font size for tablets and larger screens
                fontSize: '40px',
              },
              '@media (min-width: 1024px)': {
                // Adjust font size for desktops and larger screens
                fontSize: '50px',
              },
            }}
          >
            {heading}
          </Typography>
        </Box>
        {subHeading && (
          <Typography
            sx={{
              ...TypographyStyle,
              fontSize: '12px', // Default font size for smaller screens
              fontWeight: 500,
              color: 'white',
              paddingLeft: '10px',
              '@media (min-width: 768px)': {
                // Adjust font size for tablets and larger screens
                fontSize: '16px',
              },
              '@media (min-width: 1024px)': {
                // Adjust font size for desktops and larger screens
                fontSize: '20px',
              },
            }}
          >
            {subHeading}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
export default Heading;
