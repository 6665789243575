// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDv-5hnyRQ_mvScTg18LF4efVvA8RGBlFE",
//   authDomain: "academic-88888.firebaseapp.com",
//   projectId: "academic-88888",
//   storageBucket: "academic-88888.appspot.com",
//   messagingSenderId: "987771024459",
//   appId: "1:987771024459:web:cd564f20065d7af9b649ce"
// };
const firebaseConfig = {
  apiKey: "AIzaSyAjtH-LXKDqiKj5Je28C6PuIJDRU2rOBKw",
  authDomain: "iqtutors-11ecd.firebaseapp.com",
  projectId: "iqtutors-11ecd",
  storageBucket: "iqtutors-11ecd.appspot.com",
  messagingSenderId: "735088457592",
  appId: "1:735088457592:web:7ae149fcde80170c7afa8d"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export default storage;