import { Grid, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Grid container sx={{ backgroundColor: '#005CAB' }} mt={1}>
      <Grid item xs={12} md={4} m={4}>
        <Typography sx={{ color: 'white' }}>
          Amber: 07550757943
          <br />
          Email: iqtutors121@gmail.com
        </Typography>
      </Grid>
    </Grid>
  );
};
export default Footer;
