import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
} from '@mui/material';
import { TypographyStyle, navbarStyle2 } from '../Theme/styles';

const Contact = () => {
  const [via, setVia] = useState('email');
  const form = useRef();
  const handleSendWhatsApp = (e) => {
    e.preventDefault();
    console.log('a');
    const name = form.current.user_name.value;
    const number = form.current.user_number.value;
    const email = form.current.user_email.value;
    const message = form.current.message.value;
    const whatsAppUrl =
      'https://wa.me/447550757943?text=' +
      'Form Details %0a%0a' +
      'Name: ' +
      name +
      '%0aNumber: ' +
      number +
      '%0aEmail: ' +
      email +
      '%0a%0aMessage: %0a' +
      message;
    window.open(whatsAppUrl, '_blank').focus();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_7mr0dov',
        'template_ipbqkta',
        form.current,
        'Rva26NJMhGB513qAa'
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Message sent to the contact-team ! ');
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          alert(
            'Message Not Sent , please try again, or contact through provided number.'
          );
        }
      );
  };
  return (
    <Box display='flex' justifyContent='center' width='100%'>
      <Grid container sx={{ backgroundColor: '#4CDBC4' }}>
        <Grid item xs={12} md={6}>
          <Box
            item
            xs={12}
            md={5}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#4CDBC4',
            }}
          >
            <Grid container>
              <Grid item xs={12} display='flex' justifyContent={'center'}>
                <Box mb={2} pl={2}>
                  <Typography
                    sx={{
                      ...TypographyStyle,
                      fontSize: '40px',
                      fontWeight: 700,
                      color: 'white',
                    }}
                  >
                    Contact
                  </Typography>
                  <Typography sx={{ fontSize: '25px', color: 'white' }}>
                    <br />
                    If you have any questions about the tuition I <br />
                    offer, please contact me using the details
                    <br /> below or via the contact form provided.
                    <br />
                    <br />
                    Amber: 07550757943
                    <br />
                    Email: iqtutors121@gmail.com
                    <br />
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box mb={2}>
              <Typography
                sx={{ fontSize: '20px', color: '#002E55' }}
              ></Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ backgroundColor: 'white' }}>
          <Box pl={4} fullWidth>
            <Box mb={4}>
              <Typography
                sx={{
                  ...TypographyStyle,
                  fontSize: '40px',
                  fontWeight: 700,
                  color: '#4CDBC4',
                }}
              >
                Get In Touch
              </Typography>
            </Box>
            <form
              ref={form}
              onSubmit={via === 'email' ? handleSubmit : handleSendWhatsApp}
            >
              <Box p='auto'>
                <Grid container>
                  <Grid item xs={12} mb={4}>
                    <Typography sx={{ fontSize: '18px', marginBottom: '0px' }}>
                      Name *
                    </Typography>
                    <br />
                    <input
                      name='user_name'
                      fullWidth
                      style={{
                        height: '40px',
                        width: '90%',
                        backgroundColor: '#BEF3EACC',
                        borderRadius: '8px',
                        border: '2px solid #005CAB',
                      }}
                      required
                    ></input>
                  </Grid>
                  <Grid item xs={12} mb={4}>
                    <Typography sx={{ fontSize: '18px', marginBottom: '0px' }}>
                      Phone No.
                    </Typography>
                    <br />
                    <input
                      aria-required
                      name='user_number'
                      fullWidth
                      style={{
                        height: '40px',
                        width: '90%',
                        backgroundColor: '#BEF3EACC',
                        borderRadius: '8px',
                        border: '2px solid #005CAB',
                      }}
                      type='number'
                      required
                    ></input>
                  </Grid>
                  <Grid item xs={12} mb={4}>
                    <Typography sx={{ fontSize: '18px', marginBottom: '0px' }}>
                      Email *
                    </Typography>
                    <br />
                    <input
                      aria-required
                      name='user_email'
                      fullWidth
                      style={{
                        height: '40px',
                        width: '90%',
                        backgroundColor: '#BEF3EACC',
                        borderRadius: '8px',
                        border: '2px solid #005CAB',
                      }}
                      type='email'
                      required
                    ></input>
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <Typography sx={{ fontSize: '18px', marginBottom: '0px' }}>
                      Message *
                    </Typography>
                    <br />
                    <textarea
                      aria-required
                      name='message'
                      fullWidth
                      style={{
                        width: '90%',
                        height: '150px',
                        backgroundColor: '#BEF3EACC',
                        borderRadius: '8px',
                        border: '2px solid #005CAB',
                      }}
                      required
                    ></textarea>
                  </Grid>
                </Grid>
                <FormControl>
                  <FormLabel>Send Via:</FormLabel>
                  <RadioGroup
                    value={via}
                    onChange={(e) => setVia(e.target.value)}
                  >
                    <FormControlLabel
                      value='email'
                      control={<Radio />}
                      label='Email'
                    />
                    <FormControlLabel
                      value='whatsapp'
                      control={<Radio />}
                      label='Whatsapp'
                    />
                  </RadioGroup>
                </FormControl>
                <Box sx={{ ...navbarStyle2, width: '30%', marginLeft: '0px' }}>
                  <Button style={TypographyStyle} type='submit'>
                    Send
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Contact;
