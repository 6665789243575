export const navbarStyle = {
  backgroundColor: '#FAC761',
  borderRadius: '30px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  marginLeft: '10px',
  cursor: 'pointer',
  height: '32px',
  width: '148px',
};

export const navbarStyle2 = {
  backgroundColor: '#FAC761',
  borderRadius: '30px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  cursor: 'pointer',
  height: '32px',
  width: '148px',
};

export const navbarTitleStyle = {
  fontFamily: 'Chelsea Market',
  fontSize: 32,
  fontWeight: 700,
  color: '#005CAB',
};

export const TypographyStyle = {
  fontFamily: 'Chelsea Market',
  fontSize: 14,
  color: '#005CAB',
};
