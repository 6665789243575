import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { navbarStyle2 } from '../Theme/styles';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ pdfData }) => {
  const [pageImage, setPageImage] = useState('');

  const handleClick = () => {
    window.open(pdfData.file_url, '_blank');
  };

  useEffect(() => {
    const loadPageImage = async () => {
      try {
        const documentf = await fetch(pdfData.file_url);
        const pdf = await pdfjs.getDocument(documentf).promise;
        const page = await pdf.getPage(1); // Get the first page of the PDF
        const viewport = page.getViewport({ scale: 1.0 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        await page.render(renderContext).promise;
        const imageDataUrl = canvas.toDataURL('image/png');
        setPageImage(imageDataUrl);
      } catch (error) {
        console.error('Error loading PDF page:', error);
      }
    };

    loadPageImage();
  }, [pdfData.file_url]);

  return (
    <div>
      <Box
        style={{
          width: '90%',
          cursor: 'pointer',
          background: '#f2f2f2',
          padding: '10px',
          marginBottom: '10px',
          borderRadius: '5px',
          display: 'inline-block',
        }}
      >
        <h2
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontSize: '18px',
            textOverflow: 'ellipsis',
            margin: 0,
            color: '#333',
          }}
        >
          Name: {pdfData.title}
          <br />
          {pdfData.document_type === 'A' && <>Type: 11 Plus Resources</>}
          {pdfData.document_type === 'D' && (
            <>Type: Year 6 Transition Class Resources</>
          )}
          {pdfData.document_type === 'B' && <>Type: KS3 Resources</>}
          {pdfData.document_type === 'C' && <>Type: GCSE Physical Resources</>}
        </h2>
      </Box>
      <div
        style={{
          border: '1px solid #ccc',
          borderRadius: '5px',
          marginBottom: '10px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        {pageImage && (
          <img
            src={pageImage}
            alt='PDF Page'
            style={{ width: '100%', height: '400px', borderRadius: '5px' }}
          />
        )}
      </div>
      <Box
        sx={{
          marginBottom: '8px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          onClick={handleClick}
          sx={{
            ...navbarStyle2,
            width: '25%',
          }}
          item
          xs={12}
        >
          <Typography sx={{ ...navbarStyle2 }}>View PDF</Typography>
        </Box>
      </Box>
    </div>
  );
};

export default PDFViewer;
