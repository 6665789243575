import { Box, Grid, Typography } from '@mui/material';
import whatITeach from '../../whatITeach.PNG';
import { navbarStyle, TypographyStyle } from '../Theme/styles';
import Heading from '../heading/Heading';

const WhatITeach = () => {
  return (
    <Box>
      <Heading heading={'What I Teach'} />
      <Grid container>
        <Grid item xs={12} md={6} pl={4}>
          <Box mb={2}>
            <Typography
              sx={{
                fontSize: '20px',
                color: 'black',
                lineHeight: '1.5em',
                fontSize: '18px',
              }}
            >
              I provide face to face and online tuition for:
              <br />
              <br />
              <li>
                <a href='/11-plus-resources'>11 plus tuition</a> to children
                Year 4 to 6 (Age 8-11){' '}
              </li>
              <li>
                <a href='/ks3-resources'>KS3 science</a> to children Year 7 & 8
                (Age 11-13){' '}
              </li>
              <li>
                <a href='/gcse-resources'>GCSE Physics</a> Year 9 to 11 (Age
                13-16)
              </li>
              <li>
                <a href='/transition-class-resources'>Year 6 transition</a>{' '}
                class to bridge the gap from primary to secondary for science
                class only run in May.
              </li>
              <br />
              <br />I cover:
              <br />
              <br />
              <li>Energy</li>
              <li>Electricity</li>
              <li>Particle model of matter</li>
              <li>Atomic structure</li>
              <li>Forces</li>
              <li>Waves</li>
              <li>Magnetism and electromagnetism</li>
              <li>Space physics (physics only)</li>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{height:'530px'}}>
          <Box
            style={{
              backgroundImage: `url(${whatITeach})`,
              width: '100%',
              height: '90%',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
              
          <Box width={'100%'} mt={2} display='flex' justifyContent='center'>
            <Box sx={{ ...navbarStyle, marginTop: '0px', width: '20%' }}>
            <a
                href='/packages'
                style={{
                  ...TypographyStyle,
                  marginTop: '0px',
                  textDecoration: 'none',
                }}
              >
                Packages
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default WhatITeach;
