import { Box, Grid, Typography } from '@mui/material';
import { navbarStyle2, TypographyStyle } from '../Theme/styles';
import teacher from '../../teacher.png';

const About = () => {
  return (
    <Grid
      container
      sx={{ height: 'auto', width: '100%', backgroundColor: '#4CDBC4' }}
    >
      <Grid item xs={12} md={6}>
      <Box
  style={{
    backgroundImage: `url(${teacher})`,
    width: '100%',
    height: '100%',
    display: 'flex',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  }}
/>
      </Grid>
      <Grid item xs={12} md={1}></Grid>
      <Grid item xs={12} md={5} pr={2}>
        <Box mb={2}>
          <Typography
            sx={{
              ...TypographyStyle,
              fontSize: '50px',
              fontWeight: 700,
              color: 'white',
            }}
          >
            About Me
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography sx={{ fontSize: '20px', color: 'white' }}>
            My name is Amber and I gained my teaching qualification in Physics
            with Maths (PGCE and QTS).
            <br />
            <br />
            I have been teaching in well reputed schools in London more than eight years.
            <br />
            <br />
            Based in Redbridge, I provide group face to face and online tuition.
            <br />
          </Typography>
        </Box>
        <Box mb={2} sx={{ ...navbarStyle2, height: '40px' }}>
          <a
            href='/what-i-teach'
            style={{ ...TypographyStyle, textDecoration: 'none' }}
          >
            What I Teach
          </a>
        </Box>
      </Grid>
    </Grid>
  );
};
export default About;
