import { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { navbarStyle, TypographyStyle } from '../Theme/styles';
import Heading from '../heading/Heading';
import { LOCAL_BASE_URL } from '../../utils/utils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PasswordManager = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const admin = JSON.parse(sessionStorage.getItem('admin')) || '';
  const [usersList, setUsersList] = useState([]);
  const [passwordPr, setPasswordPr] = useState('');
  const [passwordKs, setPasswordKs] = useState('');
  const [passwordGc, setPasswordGc] = useState('');
  const [passwordPp, setPasswordPp] = useState('');

  const login = async () => {
    const URL = `${LOCAL_BASE_URL}/user/login`;
    await axios
      .post(URL, { email: admin.email, password: admin.password })
      .then((res) => {
        const response = res.data;
        if (response.status === 'good') {
          setIsAdmin(true);
        }
      });
  };
  const getUsersList = async () => {
    const { data } = await axios.get(`${LOCAL_BASE_URL}/user/getAllUsers`);
    setUsersList(data.filter((user) => user.type !== 'admin'));
  };

  const deleteUser = async (id) => {
    const URL = `${LOCAL_BASE_URL}/user/deleteUserById/${id}`;
    await axios.post(URL).then((res) => {
      const response = res.data;
      if (response.status === 'good') {
        alert(`${response.message}`);
        getUsersList();
      } else {
        {
          alert(`${response.message}`);
        }
      }
    });
    getUsersList();
  };

  const registerPr = async () => {
    if (passwordPr !== '') {
      const URL = `${LOCAL_BASE_URL}/user/registerPr`;
      await axios.post(URL, { password: passwordPr }).then((res) => {
        const response = res.data;
        if (response.status === 'good') {
          alert(`${response.message}`);
          getUsersList();
        } else {
          {
            alert(`${response.message}`);
          }
        }
      });
    } else {
      alert('Password field is empty for Primary Resources');
    }
  };

  const registerKs = async () => {
    if (passwordKs !== '') {
      const URL = `${LOCAL_BASE_URL}/user/registerKs`;
      await axios.post(URL, { password: passwordKs }).then((res) => {
        const response = res.data;
        if (response.status === 'good') {
          alert(`${response.message}`);
          getUsersList();
        } else {
          {
            alert(`${response.message}`);
          }
        }
      });
    } else {
      alert('Password field is empty for Primary Resources');
    }
  };

  const registerGc = async () => {
    if (passwordGc !== '') {
      const URL = `${LOCAL_BASE_URL}/user/registerGc`;
      await axios.post(URL, { password: passwordGc }).then((res) => {
        const response = res.data;
        if (response.status === 'good') {
          alert(`${response.message}`);
          getUsersList();
        } else {
          {
            alert(`${response.message}`);
          }
        }
      });
    } else {
      alert('Password field is empty for Primary Resources');
    }
  };

  const registerPp = async () => {
    if (passwordPp !== '') {
      const URL = `${LOCAL_BASE_URL}/user/registerPp`;
      await axios.post(URL, { password: passwordPp }).then((res) => {
        const response = res.data;
        if (response.status === 'good') {
          alert(`${response.message}`);
          getUsersList();
        } else {
          {
            alert(`${response.message}`);
          }
        }
      });
    } else {
      alert('Password field is empty for Primary Resources');
    }
  };

  useEffect(() => {
    getUsersList();
    admin && login();
  }, []);

  return (
    <>
      {isAdmin ? (
        <>
          <Heading heading={'Add Passwords Here'} />
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            // width='100%'
          >
            <Grid container mb={2} width='auto'>
              <Grid item xs={12} md={6} mb={2}>
                <h3>11 Plus Resources</h3>
                <input
                  onChange={(e) => setPasswordPr(e.target.value)}
                  style={{
                    height: '40px',
                    width: '80%',
                    backgroundColor: '#BEF3EACC',
                    borderRadius: '8px',
                    border: '2px solid #005CAB',
                    marginBottom: '12px',
                  }}
                  placeholder='Enter 11 Plus Resources Password here'
                />
                <Box
                  sx={{ ...navbarStyle, width: '20%', marginLeft: '30%' }}
                  onClick={() => registerPr()}
                >
                  <Typography style={TypographyStyle}>Add</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <h3>Year 6 Transition Class Resources</h3>
                <input
                  onChange={(e) => setPasswordPp(e.target.value)}
                  style={{
                    height: '40px',
                    width: '80%',
                    backgroundColor: '#BEF3EACC',
                    borderRadius: '8px',
                    border: '2px solid #005CAB',
                    marginBottom: '12px',
                  }}
                  placeholder='Enter Transition Class Resources Password here'
                />
                <Box
                  sx={{ ...navbarStyle, width: '20%', marginLeft: '30%' }}
                  onClick={() => registerPp()}
                >
                  <Typography style={TypographyStyle}>Add</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <h3>KS3 Resources</h3>
                <input
                  onChange={(e) => setPasswordKs(e.target.value)}
                  style={{
                    height: '40px',
                    width: '80%',
                    backgroundColor: '#BEF3EACC',
                    borderRadius: '8px',
                    border: '2px solid #005CAB',
                    marginBottom: '12px',
                  }}
                  placeholder='Enter KS3 Resources Password here'
                />
                <Box
                  sx={{ ...navbarStyle, width: '20%', marginLeft: '30%' }}
                  onClick={() => registerKs()}
                >
                  <Typography style={TypographyStyle}>Add</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <h3>GCSE Resources</h3>
                <input
                  onChange={(e) => setPasswordGc(e.target.value)}
                  style={{
                    height: '40px',
                    width: '80%',
                    backgroundColor: '#BEF3EACC',
                    borderRadius: '8px',
                    border: '2px solid #005CAB',
                    marginBottom: '12px',
                  }}
                  placeholder='Enter GCSE Resources Password here'
                />
                <Box
                  sx={{ ...navbarStyle, width: '20%', marginLeft: '30%' }}
                  onClick={() => registerGc()}
                >
                  <Typography style={TypographyStyle}>Add</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Heading heading={'Manage All Passwords'} />
          <Box m={2} display='flex' justifyContent={'center'}>
            {usersList.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{ width: '70%', border: '2px solid #005CAB' }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='center'>Password</StyledTableCell>
                      <StyledTableCell align='center'>Type</StyledTableCell>
                      <StyledTableCell align='center'>Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersList.map((user) => (
                      <StyledTableRow>
                        <StyledTableCell
                          align='center'
                          component='th'
                          scope='row'
                        >
                          {user.password}
                        </StyledTableCell>
                        <StyledTableCell
                          align='center'
                          component='th'
                          scope='row'
                        >
                          {user.type}
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          <Button
                            onClick={() => deleteUser(user._id)}
                            variant='contained'
                            sx={{ backgroundColor: 'red' }}
                          >
                            Delete
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box item xs={12} md={4} sx={{ height: 'auto' }}>
                <p>Currently no passwords set ! </p>
              </Box>
            )}
          </Box>
          {/* </Grid> */}
        </>
      ) : (
        <h2>Admin not logged in! (This route is only allowed to admin)</h2>
      )}
    </>
  );
};

export default PasswordManager;
