import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { navbarStyle2 } from '../Theme/styles';
import { LOCAL_BASE_URL } from '../../utils/utils';
import axios from 'axios'; // Make sure to import axios
import { pdfjs } from 'react-pdf';
import { ref, deleteObject } from "firebase/storage";
import storage from './firebaseConfig'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ pdfData, getFilesList }) => {
  const [pageImage, setPageImage] = useState('');

  const handleClick = () => {
    window.open(pdfData.file_url, '_blank');
  };

  const deleteFileFromMongoDb=async()=>{
    try {
      const { data } = await axios.post(
        `${LOCAL_BASE_URL}/deleteFileById/${pdfData._id}`
      );
      await getFilesList();
      alert(data.message);
      window.location.reload(false);
    } catch (error) {
      console.error('Error deleting file from MongoDb:', error);
    }
  }

  const deleteFileFromFirebase = async () => {
    const desertRef = ref(storage, pdfData.file_path);
    deleteObject(desertRef)
      .then(async () => {
        await deleteFileFromMongoDb();
      })
      .catch((error) => {
        console.error('Error deleting file from Firebase:', error);
      });
  };

  useEffect(() => {
    const loadPageImage = async () => {
      try {
          // Handle PDF format
          const documentf = await fetch(pdfData.file_url);
          const pdf = await pdfjs.getDocument(documentf).promise;
          const page = await pdf.getPage(1);
          const viewport = page.getViewport({ scale: 1.0 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = viewport.width;
          canvas.height = viewport.height;
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;
          const imageDataUrl = canvas.toDataURL('image/png');
          setPageImage(imageDataUrl);
      } catch (error) {
        console.error('Error loading document:', error);
      }
    };
    

    loadPageImage();
  }, [pdfData.file_url]);

  return (
    <div>
      <Box
        style={{
          width: '90%',
          cursor: 'pointer',
          background: '#f2f2f2',
          padding: '10px',
          marginBottom: '10px',
          borderRadius: '5px',
          display: 'inline-block',
        }}
      >
        <h2
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontSize: '18px',
            textOverflow: 'ellipsis',
            margin: 0,
            color: '#333',
          }}
        >
          Name: {pdfData.title}
          <br />
          {pdfData.document_type === 'A' && <>Type: 11 Plus Resources</>}
          {pdfData.document_type === 'D' && (
            <>Type: Year 6 Transition Class Resources</>
          )}
          {pdfData.document_type === 'B' && <>Type: KS3 Resources</>}
          {pdfData.document_type === 'C' && <>Type: GCSE Physical Resources</>}
        </h2>
      </Box>
      <div
        style={{
          border: '1px solid #ccc',
          borderRadius: '5px',
          marginBottom: '10px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        {pageImage && (
          <img
            src={pageImage}
            alt='PDF Page'
            style={{ width: '100%', height: '400px', borderRadius: '5px' }}
          />
        )}
      </div>
      <Box
        sx={{
          marginBottom: '8px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          onClick={handleClick}
          sx={{
            ...navbarStyle2,
            width: '25%',
          }}
          item
          xs={12}
        >
          <Typography sx={{ ...navbarStyle2 }}>View PDF</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          onClick={deleteFileFromFirebase}
          sx={{
            ...navbarStyle2,
            width: '25%',
            backgroundColor: '#FF0000',
          }}
          item
          xs={12}
        >
          <Typography sx={{  color: 'white' }}>Delete</Typography>
        </Box>
      </Box>
    </div>
  );
};

export default PDFViewer;
