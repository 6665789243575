import { Box, Grid, Typography } from '@mui/material';
import {
  navbarStyle,
  TypographyStyle,
} from '../Theme/styles';
import homeImage from '../../homeBackground.png';
import StudentAreaHome from '../student-area/StudentAreaHome';

const Home = () => {
  return (
    <Box>
  <Box
  style={{
    backgroundImage: `url(${homeImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '700px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      height: '500px', // Adjust the height for mobile
      backgroundSize: 'cover',
    },
  }}
>
    <Box
      style={{
        backgroundColor: '#e7c3f7',
        width: '90%', // Adjust width for smaller screens
        maxWidth: '500px', // Add max-width to prevent it from becoming too wide
        height: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
      }}
    >
      <Typography
        style={{
          fontSize: '25px', // Decrease font size for smaller screens
          fontWeight: 700,
          textAlign: 'center',
        }}
      >
        Online and face to face <br /> tuition for children <br /> aged 8 - 16
      </Typography>
    </Box>
  </Box>

  <Grid container sx={{ height: 'auto', width: '100%' }} mt={4}>
    <Grid item xs={12} md={12}>
      <Box mb={2}>
        <Typography
          sx={{
            fontSize: '40px', // Decrease font size for smaller screens
            fontWeight: 700,
            textAlign: 'center',
            lineHeight: '1.5em',
          }}
        >
          Why choose IQ tutors ?
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography
          sx={{
            fontSize: '20px', // Decrease font size for smaller screens
            color: '#002E55',
            textAlign: 'center',
            lineHeight: '1.5em',
          }}
        >
          I provide face to face and online tutoring in small groups. My aim
          to provide children strong foundation not only for eleven plus but
          also give them a solid footing into secondary and Sixth Form
          school.
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 'auto',
          lineHeight: '1.5em',
        }}
      >
        <Box sx={navbarStyle}>
          <a href='/about' style={{ ...TypographyStyle, textDecoration: 'none' }}>
            More About Me
          </a>
        </Box>
      </Box>
    </Grid>
  </Grid>
    <StudentAreaHome />
</Box>

  );
};

export default Home;
