import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

const NavigateToHome = () => {
  const navigate = useNavigate();
  useEffect(()=>{
    navigate({
      pathname: '/home',
    });
  },[])
 return <></> 
};

export default NavigateToHome;
