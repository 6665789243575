import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { Box } from '@mui/material';
import Navbar from './navbar/Navbar';
import Home from './home/Home';
import About from './about/About';
import Footer from './footer/Footer';
import WhatITeach from './what-i-teach/WhatITeach';
import StudentArea from './student-area/StudentArea';
import PlusResources from './student-area/PlusResources';
import PlusResourcesLogin from './login/PlusResourcesLogin';
import Documents from './student-area/Documents';
import Login from './login/Login';
import Ks3Resources from './student-area/Ks3Resources';
import Ks3ResourcesLogin from './login/Ks3ResourcesLogin';
import TransitionClassResources from './student-area/TransitionClassResources';
import GcseResources from './student-area/GcseResources';
import GcseResourcesLogin from './login/GcseResourcesLogin';
import TransitionClassResourcesLogin from './login/TransitionClassResourcesLogin';
import PasswordManager from './student-area/PasswordManager';
import Contact from './contact/Contact';
import Packages from './packages/Packages';
import NavigateToHome from './home/NavigateToHome';

function MainComponent() {
  return (
    <Box>
      <Box>
        <Navbar />
      </Box>
      <Box>
        <Router>
          <Routes>
            <Route path='/' element={<NavigateToHome />} />
            <Route path='/home' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/what-i-teach' element={<WhatITeach />} />
            <Route path='/packages' element={<Packages />} />
            <Route path='/student-area' element={<StudentArea />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/login' element={<Login />} />
            <Route path='/dashboard' element={<Documents />} />
            <Route path='/11-plus-resources' element={<PlusResources />} />
            <Route path='/ks3-resources' element={<Ks3Resources />} />
            <Route path='/gcse-resources' element={<GcseResources />} />
            <Route path='/transition-class-resources' element={<TransitionClassResources />} />
            <Route
              path='/11-plus-resources-login'
              element={<PlusResourcesLogin />}
            />
            <Route
              path='/ks3-resources-login'
              element={<Ks3ResourcesLogin />}
            />
            <Route
              path='/gcse-resources-login'
              element={<GcseResourcesLogin />}
            />
            <Route path='/transition-class-resources-login' element={<TransitionClassResourcesLogin />} />
            <Route path='/password-manager' element={<PasswordManager />} />
          </Routes>
        </Router>
      </Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  );
}

export default MainComponent;
