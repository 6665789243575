import { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Grid, Typography } from '@mui/material';
import { navbarStyle, TypographyStyle } from '../Theme/styles';
import { LOCAL_BASE_URL } from '../../utils/utils';

const StudentAreaHome = () => {
  const [isPrimaryUser, setIsPrimaryUser] = useState(false);
  const [isKs3User, setIsKs3User] = useState(false);
  const [isGcseUser, setIsGcseUser] = useState(false);
  const [isPastPapersUser, setIsPastPapersUser] = useState(false);
  const primaryUser = JSON.parse(sessionStorage.getItem('plusUser')) || '';
  const ks3User = JSON.parse(sessionStorage.getItem('ks3User')) || '';
  const gcseUser = JSON.parse(sessionStorage.getItem('gcseUser')) || '';
  const pastPapersUser =
    JSON.parse(sessionStorage.getItem('transitionUser')) || '';

  const loginPrimaryUser = async () => {
    const URL = `${LOCAL_BASE_URL}/user/primaryResourcesLogin`;
    await axios.post(URL, { password: primaryUser.password }).then((res) => {
      const response = res.data;
      if (response.status === 'good') {
        setIsPrimaryUser(true);
      }
    });
  };

  const loginKs3User = async () => {
    const URL = `${LOCAL_BASE_URL}/user/ks3ResourcesLogin`;
    await axios.post(URL, { password: ks3User.password }).then((res) => {
      const response = res.data;
      if (response.status === 'good') {
        setIsKs3User(true);
      }
    });
  };

  const loginGcseUser = async () => {
    const URL = `${LOCAL_BASE_URL}/user/gcseResourcesLogin`;
    await axios.post(URL, { password: gcseUser.password }).then((res) => {
      const response = res.data;
      if (response.status === 'good') {
        setIsGcseUser(true);
      }
    });
  };

  const loginPastPapersUser = async () => {
    const URL = `${LOCAL_BASE_URL}/user/pastPapersLogin`;
    await axios.post(URL, { password: pastPapersUser.password }).then((res) => {
      const response = res.data;
      if (response.status === 'good') {
        setIsPastPapersUser(true);
      }
    });
  };
  useEffect(() => {
    primaryUser && loginPrimaryUser();
    ks3User && loginKs3User();
    gcseUser && loginGcseUser();
    pastPapersUser && loginPastPapersUser();
  }, []);
  return (
      <Grid
        container

      sx={{width:'100%',backgroundColor: '#BEF3EA' }}
        mt={1}
        mb={4}
        justifyContent={'center'}
      >
        <Grid
          item
          xs={12}
          md={2.6}
          m={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '300px',
            backgroundColor: '#B0CFEA',
          }}
        >
          <Box sx={navbarStyle}>
            {isPrimaryUser ? (
              <>
                <a href='/11-plus-resources' style={{ textDecoration: 'none' }}>
                  <Typography style={TypographyStyle}>
                    11 Plus Resources
                  </Typography>
                </a>
              </>
            ) : (
              <>
                <a
                  href='/11-plus-resources-login'
                  style={{ textDecoration: 'none' }}
                >
                  <Typography style={TypographyStyle}>
                    11 Plus Resources
                  </Typography>
                </a>
              </>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.6}
          m={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '300px',
            backgroundColor: '#FFDE5F',
          }}
        >
          <Box sx={{...navbarStyle,width:"93%"}}>
            {isPastPapersUser ? (
              <>
                <a href='/transition-class-resources' style={{ textDecoration: 'none' }}>
                  <Typography style={TypographyStyle}>
                    Year 6 Transition Class Resources
                  </Typography>
                </a>
              </>
            ) : (
              <>
                <a href='/transition-class-resources-login' style={{ textDecoration: 'none' }}>
                  <Typography style={TypographyStyle}>
                    Year 6 Transition Class Resources
                  </Typography>
                </a>
              </>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.6}
          m={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '300px',
            backgroundColor: '#B0CFEA',
          }}
        >
          <Box sx={navbarStyle}>
            {isKs3User ? (
              <>
                <a href='/ks3-resources' style={{ textDecoration: 'none' }}>
                  <Typography style={TypographyStyle}>KS3 Resources</Typography>
                </a>
              </>
            ) : (
              <>
                <a
                  href='/ks3-resources-login'
                  style={{ textDecoration: 'none' }}
                >
                  <Typography style={TypographyStyle}>KS3 Resources</Typography>
                </a>
              </>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.6}
          m={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '300px',
            backgroundColor: '#9CE7DB',
          }}
        >
          <Box sx={{...navbarStyle,width:"83%"}}>
            {isGcseUser ? (
              <>
                <a href='/gcse-resources' style={{ textDecoration: 'none' }}>
                  <Typography style={TypographyStyle}>
                    GCSE Physical Resources
                  </Typography>
                </a>
              </>
            ) : (
              <>
                <a
                  href='/gcse-resources-login'
                  style={{ textDecoration: 'none' }}
                >
                  <Typography style={TypographyStyle}>
                    GCSE Physical Resources
                  </Typography>
                </a>
              </>
            )}
          </Box>
        </Grid>
        
      </Grid>
  );
};

export default StudentAreaHome;
