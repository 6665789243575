import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Grid, Typography, Box } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PDFPreview from './PDFPreview';
import Heading from '../heading/Heading';
import { navbarStyle2, TypographyStyle } from '../Theme/styles';
import storage from './firebaseConfig'
import { LOCAL_BASE_URL } from '../../utils/utils';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

const fileTypes = ['pdf'];

const Documents = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [pdfDataList, setPdfDataList] = useState([]);
  const admin = JSON.parse(sessionStorage.getItem('admin')) || '';
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [documentFilter, setDocumentFilter] = useState('');

  const handleChangeFilter = (event) => {
    setDocumentFilter(event.target.value);
    const filter = event.target.value;
    if (filter === 'all') setFilteredDocuments([...pdfDataList]);
    else if (filter === 'a')
      setFilteredDocuments(
        pdfDataList.filter((doc) => {
          return doc.document_type === 'A';
        })
      );
    else if (filter === 'b')
      setFilteredDocuments(
        pdfDataList.filter((doc) => {
          return doc.document_type === 'B';
        })
      );
    else if (filter === 'c')
      setFilteredDocuments(
        pdfDataList.filter((doc) => {
          return doc.document_type === 'C';
        })
      );
    else if (filter === 'd')
      setFilteredDocuments(
        pdfDataList.filter((doc) => {
          return doc.document_type === 'D';
        })
      );
  };

  const login = async () => {
    const URL = `${LOCAL_BASE_URL}/user/login`;
    await axios
      .post(URL, { email: admin.email, password: admin.password })
      .then((res) => {
        const response = res.data;
        if (response.status === 'good') {
          setIsAdmin(true);
        }
      });
  };

  const [fileA, setFileA] = useState(null);
  const [fileB, setFileB] = useState(null);
  const [fileC, setFileC] = useState(null);
  const [fileD, setFileD] = useState(null);
  const handleChangeA = (file) => {
    setFileA(file);
  };
  const handleChangeB = (file) => {
    setFileB(file);
  };
  const handleChangeC = (file) => {
    setFileC(file);
  };

  const handleChangeD = (file) => {
    setFileD(file);
  };

  const upload = async (type) => {
    let file=undefined;
    if(type === 'A') {file=fileA};
    if(type === 'B') {file=fileB};
    if(type === 'C') {file=fileC};
    if(type === 'D') {file=fileD};

    if (!file) return ()=>{alert('select a file!')};
    const URL = `${LOCAL_BASE_URL}/upload${type}`;
    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          console.log('fileURl',{downloadURL});
          const body = {
            fileName: file.name,
            file_url: downloadURL,
            file_path: `files/${file.name}`,
          };
          await axios.post(URL, body).then((res) => {
            const response = res.data;
            if (response.status === 'good') {
              alert(`${response.message}`);
              window.location.reload();
            } else {
              {
                alert(`${response.message}`);
              }
            }
          });
        });
      }
    );
  };

  const getFilesList = async () => {
    const { data } = await axios.get(`${LOCAL_BASE_URL}/getAllFiles`);
    setPdfDataList(data);
  };

  useEffect(() => {
    getFilesList();
    admin && login();
  }, []);

  return (
    <Box minHeight='300px'>
      {isAdmin ? (
        <>
          <Heading heading={'Password Manager'} />
          <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
            <a href='/password-manager' style={{ textDecoration: 'none' }}>
              <Grid
                sx={{
                  ...navbarStyle2,
                  border: '2px solid #005CAB',
                }}
                m={2}
                item
              >
                <Typography sx={{ ...TypographyStyle }}>
                  Manage Passwords
                </Typography>
              </Grid>
            </a>
          </Grid>
          <Heading heading={'Upload Your Documents Here'} />

          <Grid container p={8}>
            <Grid item xs={12} md={6}>
              <h3>11 Plus Resources</h3>

              <FileUploader
                handleChange={handleChangeA}
                name='file'
                types={fileTypes}
              />
              <Button onClick={()=>upload('A')}>Upload</Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <h3>Year 6 Transition Class Resources</h3>
              <FileUploader
                handleChange={handleChangeD}
                name='file'
                types={fileTypes}
              />
              <Button onClick={()=>upload('D')}>Upload</Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <h3>KS3 Resources</h3>
              <FileUploader
                style={{ height: '10px' }}
                handleChange={handleChangeB}
                name='file'
                types={fileTypes}
              />
              <Button onClick={()=>upload('B')}>Upload</Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <h3>GCSE Resources</h3>

              <FileUploader
                handleChange={handleChangeC}
                name='file'
                types={fileTypes}
              />
              <Button onClick={()=>upload('C')}>Upload</Button>
            </Grid>
          </Grid>
          <Heading heading={'Uploaded Documents'} />
          <Grid container spacing={1}>
            <Grid item xs={12} mt={2} mb={2}>
              <InputLabel id='demo-simple-select-label'>
                Document Filter
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                sx={{ width: '40%' }}
                value={documentFilter}
                label='Document Filter'
                onChange={handleChangeFilter}
              >
                <MenuItem value='all'>All</MenuItem>
                <MenuItem value='a'>11 Plus Resources</MenuItem>
                <MenuItem value='d'>Transition Class Resources</MenuItem>
                <MenuItem value='b'>KS3 Resources</MenuItem>
                <MenuItem value='c'>GCSE Resources</MenuItem>
              </Select>
              {pdfDataList.length > 0 && documentFilter === '' && (
                <h4>Please select filter! </h4>
              )}
            </Grid>
            {pdfDataList.length > 0 ? (
              filteredDocuments.map((pdfData) => {
                return (
                  <Grid item xs={12} md={4} sx={{ height: 'auto' }}>
                    <PDFPreview pdfData={pdfData} getFilesList={getFilesList} />
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12} md={4} sx={{ height: 'auto' }}>
                <p>Currently no pdfs uploaded! </p>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <h2>Admin not logged in! (This route is only allowed to admin)</h2>
      )}
    </Box>
  );
};

export default Documents;
