import MainComponent from './components/MainComponent';

function App() {
  return (
    <>
      <head>
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
        <link
          href='https://fonts.googleapis.com/css2?family=Chelsea+Market&display=swap'
          rel='stylesheet'
        ></link>
      </head>
      <MainComponent />
    </>
  );
}

export default App;
