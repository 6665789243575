import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Box, Card, CardContent } from '@mui/material';
import { navbarStyle, TypographyStyle } from '../Theme/styles';
import Heading from '../heading/Heading';
import { LOCAL_BASE_URL } from '../../utils/utils';

const GcseResourcesLogin = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [displayError, setDisplayError] = useState('none');
  const URL = `${LOCAL_BASE_URL}/user/gcseResourcesLogin`;
  const login = async () => {
    await axios.post(URL, { password }).then((res) => {
      const response = res.data;
      if (response.status === 'good') {
        sessionStorage.setItem('gcseUser', JSON.stringify(response.user));
        navigate({
          pathname: '/gcse-resources',
        });
      } else {
        {
          setDisplayError('');
        }
      }
    });
  };

  return (
    <>
      <Heading heading={'GCSE Physical Resources Login'} />
      <Box mb={4} display='flex' justifyContent={'center'}>
        <Typography
          sx={{
            ...TypographyStyle,
            fontSize: '30px',
            fontWeight: 700,
            color: '#4CDBC4',
          }}
        >
          Enter password provided by Admin or contact admin to get password !
        </Typography>
      </Box>
      <Box display='flex' justifyContent={'center'}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={5}>
                <Typography sx={{ fontSize: '18px', marginBottom: '0px' }}>
                  Password
                </Typography>
                <br />

                <input
                  id='name'
                  style={{
                    height: '40px',
                    width: '220px',
                    borderRadius: '8px',
                    backgroundColor: '#BEF3EACC',
                    border: '2px solid #005CAB',
                  }}
                  type='password'
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Typography color={'red'} display={displayError}>
              Wrong Password!
            </Typography>
            <Box
              m={2}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginLeft: '0px',
              }}
              onClick={login}
            >
              <Box sx={{ ...navbarStyle, width: '30%' }}>
                <Typography style={TypographyStyle}>Login</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default GcseResourcesLogin;
