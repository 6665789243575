import { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Box } from '@mui/material';
import PDFPreviewUser from './PDFPreviewUser';
import Heading from '../heading/Heading';
import { LOCAL_BASE_URL } from '../../utils/utils';

const TransitionClassResources = () => {
  const user = JSON.parse(sessionStorage.getItem('transitionUser')) || '';
  const [isUser, setIsUser] = useState(false);
  const [pdfDataList, setPdfDataList] = useState([]);

  const login = async () => {
    const URL = `${LOCAL_BASE_URL}/user/pastPapersLogin`;
    await axios.post(URL, { password: user.password }).then((res) => {
      const response = res.data;
      if (response.status === 'good') {
        setIsUser(true);
      }
    });
  };

  const getFilesList = async () => {
    const { data } = await axios.get(`${LOCAL_BASE_URL}/getAllFilesD`);
    setPdfDataList(data);
  };

  useEffect(() => {
    getFilesList();
    login();
  }, []);

  return (
    <Box minHeight={'300px'}>
      {isUser ? (
        <>
          <Heading heading={'Transition Class Resources'} />

          <Grid container spacing={1}>
            {pdfDataList.length > 0 ? (
              pdfDataList.map((pdfData) => {
                return (
                  <Grid item xs={12} md={4} sx={{ height: 'auto' }}>
                    <PDFPreviewUser
                      pdfData={pdfData}
                      getFilesList={getFilesList}
                    />
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12} md={4} sx={{ height: 'auto' }}>
                <p>Currently no pdfs uploaded! </p>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <h2>
          You are not authorized, kindly provide password to get access.{' '}
          <a href='transition-class-resources-login'>Click here to Add password</a>
        </h2>
      )}
    </Box>
  );
};

export default TransitionClassResources;
