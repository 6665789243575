import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Box, Card, CardContent } from '@mui/material';
import { navbarStyle, navbarStyle2, TypographyStyle } from '../Theme/styles';
import { LOCAL_BASE_URL } from '../../utils/utils';

const Login = () => {
  const [displayError, setDisplayError] = useState('none');
  const [errorMsg, setErrorMsg] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const admin = JSON.parse(sessionStorage.getItem('admin')) || '';
  const URL = `${LOCAL_BASE_URL}/user/login`;
  const login = async () => {
    await axios.post(URL, { email, password }).then((res) => {
      const response = res.data;
      if (response.status === 'good') {
        sessionStorage.setItem('admin', JSON.stringify(response.user));
        window.location.replace('/dashboard');
      } else {
        {
          setErrorMsg(response.message);
          setDisplayError('');
        }
      }
    });
  };

  useEffect(() => {
    if (admin !== '') {
      window.location.replace('/dashboard');
    }
  }, []);

  return (
    <Box display='flex' justifyContent={'center'}>
      <Card>
        <CardContent>
          <Box mb={2}>
            <Typography
              sx={{
                ...TypographyStyle,
                fontSize: '40px',
                fontWeight: 700,
                color: '#4CDBC4',
              }}
            >
              Admin Login
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography sx={{ fontSize: '18px', marginBottom: '0px' }}>
              Email
            </Typography>
            <br />
            <input
              id='name'
              style={{
                height: '40px',
                width: '95%',
                backgroundColor: '#BEF3EACC',
                borderRadius: '8px',
                border: '2px solid #005CAB',
              }}
              placeholder='Enter Email'
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: '18px', marginBottom: '0px' }}>
              Password
            </Typography>
            <br />
            <input
              id='name'
              style={{
                height: '40px',
                width: '95%',
                backgroundColor: '#BEF3EACC',
                borderRadius: '8px',
                border: '2px solid #005CAB',
              }}
              placeholder='Enter Password'
              type='password'
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Box>
          <Typography color={'red'} display={displayError}>
            {errorMsg}
          </Typography>
          <Box width='100%' display='flex' justifyContent={'center'} mt={2}>
          <Box
            sx={{ ...navbarStyle2, width: '25%' }}
            onClick={login}       
          >
            <Box>
              <Typography style={TypographyStyle}>Login</Typography>
            </Box>
          </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Login;
